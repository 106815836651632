import { DECLINE_METTING, GET_CALENDAR_EVENTS } from "./URL";
import axiosInstance from "./axiosInstance";


export const getAllEvents = async (dateRange) => {
  const formattedDate = formatDate(dateRange);
  // console.log("formattedDate", formattedDate);

  const URL = GET_CALENDAR_EVENTS + `&from=${formattedDate.startDate}&to=${formattedDate.endDate}&type=scheduled`;
  // console.log("getAllEvents===>", URL);
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  let data = {
    hrEmail: sessionStorage.getItem("mail"),
  };

  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};

const formatDate = (dateRange) => {
  const startDateString = dateRange.startDate;
  const date1 = new Date(startDateString);
  // Get the day
  const day = date1.getDate();
  // Get the month
  const month = date1.getMonth() + 1;
  // Get the year
  const year = date1.getFullYear();
  const newStartData = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
    }`;
  const endDateString = dateRange.endDate;
  const date2 = new Date(endDateString);
  // Get the day
  const day2 = date2.getDate();
  // Get the month
  const month2 = date2.getMonth() + 1;
  // Get the year
  const year2 = date2.getFullYear();
  const newEndData = `${year2}-${month2 < 10 ? "0" + month2 : month2}-${day2 < 10 ? "0" + day2 : day2
    }`;

  return {
    startDate: newStartData,
    endDate: newEndData,
  };
};


export const declineMeeting = async (meetID, data, status) => {
  const endpoint = DECLINE_METTING;
  const URL = endpoint + `/${meetID}?meetingStatus=${status}`

  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
  };

  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
}