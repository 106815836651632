
import { MONTH_EVENT, PENDING_PROJECT } from "./URL";
import axiosInstance from "./axiosInstance";

export const currentMonthEvents = async () => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const endPoint = MONTH_EVENT

  const data = currentMonthData();

  return await axiosInstance.post(endPoint, data, Config).then((res) => res.data);
};

const currentMonthData = () => {
  // Get today's date
  var today = new Date();

  // Extract the month number from the date
  var month = String(today.getMonth() + 1).padStart(2, "0");

  // Format the date as "YYYY-MM-DD"
  console.log(
    "checkDateEmpty",
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
    "c",
    today.getDate() < 10
  );

  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  var formattedDate = today.getFullYear() + "-" + month + "-" + todayDate;

  console.log("formattedDate==>", formattedDate);

  const data = {
    month: month,
    date: formattedDate,
    hrEmail: sessionStorage.getItem("mail"),
  };
  return data;
};

export const getPendingProjects = async () => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const endPoint = PENDING_PROJECT

  var today = new Date();

  // Extract the month number from the date
  var month = String(today.getMonth() + 1).padStart(2, "0");

  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  // Format the date as "YYYY-MM-DD"

  var formattedDate = todayDate + "-" + month + "-" + today.getFullYear();

  const data = {
    date: formattedDate,
    hrId: sessionStorage.getItem("mail"),
  };

  return await axiosInstance.post(endPoint, data, Config).then((res) => res.data);
};
