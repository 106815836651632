import { FLAG_USER } from "./URL";
import axiosInstance from "./axiosInstance";

export const flagUser = async (data) => {
    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };
    const URL = FLAG_USER;

    return await axiosInstance.post(URL, data, Config).then((res) => res.data);
}