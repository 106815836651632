import React from "react";
import styles from "./PreviousRoundPerformance.module.scss";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
// const text1 = "View Report";
const text2 = "View Recordings";
const ReportCard = ({ candidateProfile, meetingDetails }) => {
  const navigate = useNavigate();

  const handleViewRecordingClick = (round) => {
    localStorage.setItem("meetingDetails", JSON.stringify(meetingDetails));
    navigate(
      `/dev-recording/screen?user=${candidateProfile?.developerResponse?.id}&round=${round}`
    );
  };

  return (
    <div className={styles.reportCardContainer}>
      <div className={styles.title}>Previous Round Performance</div>
      <div className={styles.reportCard}>
        <div className={styles.round}>
          <div className={styles.details}>
            <h3>
              <span> Round 1: </span>Technical Fundamentals Test
            </h3>
            <p className={styles.score}>
              <strong>
                {candidateProfile?.assessmentScoreRating?.round1Rating.toFixed(
                  2
                )}
              </strong>
              /5
            </p>
          </div>
          <div className={styles.actions}>
            {/* <button className={styles.button}>
              <FileDownloadOutlinedIcon /> {text1}
            </button> */}
            <button
              className={styles.button}
              onClick={() => handleViewRecordingClick("round1")}
            >
              <VisibilityOutlinedIcon />
              {text2}
            </button>
          </div>
        </div>
        <div className={styles.round}>
          <div className={styles.details}>
            <h3>
              <span>Round 2:</span> Coding Challenge
            </h3>
            <p className={styles.score}>
              <strong>
                {" "}
                {candidateProfile?.assessmentScoreRating?.round2Rating.toFixed(
                  2
                )}
              </strong>
              /5
            </p>
          </div>
          <div className={styles.actions}>
            {/* <button className={styles.button}>
              {" "}
              <FileDownloadOutlinedIcon />
              {text1}
            </button> */}
            <button
              className={styles.button}
              onClick={() => handleViewRecordingClick("round2")}
            >
              <VisibilityOutlinedIcon /> {text2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
