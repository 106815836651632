import { useEffect, useState } from "react";
import { DashboardStats } from "../../Component/Dashboard/Stats/Stats";
import { Header } from "../../Component/Header/Header";
import {
  currentMonthEvents,
  getPendingProjects,
} from "../../Apis/monthEventDetails";
import MyCalendar from "../../Component/Dashboard/CalendarUI/MyCalendar";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [currentMonthDetails, setCurrentMonthDetails] = useState({});

  const pendingProject = async () => {
    try {
      const res = await getPendingProjects();
      console.log("pendingProject==>", res);
      return res;
    } catch (err) {
      console.log("pendingProject==>", err);
    }
  };

  const monthEvents = async () => {
    try {
      setLoading(true);
      const res = await currentMonthEvents();
      const res2 = await pendingProject();
      setCurrentMonthDetails({ ...res, pending: res2 });
    } catch (err) {
      console.log("monthEvents ===>", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    monthEvents();
  }, []);

  return (
    <div>
      <Header />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <DashboardStats currentMonthDetails={currentMonthDetails} />
      )}
      <MyCalendar />
    </div>
  );
};
