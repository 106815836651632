// CustomDateHeader.jsx
import React from "react";
import moment from "moment";
import "./CustomDateHeader.scss";

const CustomDateHeader = ({ label, date }) => {
  return (
    <div className="custom-date-header">
      <div className="weekday">{moment(date).format("dddd")}</div>
      <div className="date">{moment(date).format("MMM DD")}</div>
    </div>
  );
};

export default CustomDateHeader;
