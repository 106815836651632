
import { GET_ALL_PROJECTS_FOR_HR, VIEW_ASSIGNED_PROJECT } from "./URL";
import axiosInstance from "./axiosInstance";

export const getAllProjectsForHr = async (data) => {

    const URL = GET_ALL_PROJECTS_FOR_HR;

    let Config = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
    };

    return await axiosInstance.post(URL, data, Config).then((res) => res);
};



export const viewProjectAssigned = async (data) => {

    const URL = VIEW_ASSIGNED_PROJECT

    // let Config = {
    //     headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //     },
    // };

    const response = await axiosInstance.post(URL, data, {
        responseType: "blob", // Important for handling binary data like files
        // responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], { type: "application/pdf" });
    // Create a URL for the blob
    const pdfBlobUrl = window.URL.createObjectURL(blob);
    // Open the PDF in a new tab
    window.open(pdfBlobUrl, "_blank");

};