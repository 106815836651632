
import { HR_LOGIN } from "./URL";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

const customMessage = "User is Not an HR"
const somethingWrong = "Something went wrong! Try again."

export const hrLogin = async (data) => {
    try {
        const URL = HR_LOGIN;

        let Config = {
            // headers: {
            //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            // },
        };

        const response = await axiosInstance.post(URL, data, Config).then((res) => res);

        if (
            response?.data?.role.toLowerCase() === "hr" &&
            response?.headers?.authorization
        ) {
            sessionStorage.setItem("token", response?.headers?.authorization);
            sessionStorage.setItem("mail", data?.officialMail);
            sessionStorage.setItem("role", response?.data?.role);
            return true;

        } else {
            throw new Error(customMessage);
        }
    } catch (err) {
        let msg;
        if (err.message === customMessage) {
            msg = err.message;
        } else if (err?.response?.data?.exception) {
            msg = err?.response?.data?.exception;
        } else {
            msg = somethingWrong;
        }
        toast.error(msg);
        return false;
    }
};


export const hrLogOut = async () => {
    localStorage.clear()
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("mail");
    sessionStorage.removeItem("role");
    return true
}