import moment from "moment";

export const calculateDateRange = (currentDate, viewType) => {
    let start, end;

    switch (viewType) {
        case "day":
            start = moment(currentDate).startOf("day").toDate();
            end = moment(currentDate).endOf("day").toDate();
            break;
        case "week":
            start = moment(currentDate).startOf("week").toDate();
            end = moment(currentDate).endOf("week").toDate();
            break;
        case "month":
            start = moment(currentDate).startOf("month").toDate();
            end = moment(currentDate).endOf("month").toDate();
            break;
        default:
            start = moment(currentDate).startOf("day").toDate();
            end = moment(currentDate).endOf("day").toDate();
    }

    return { startDate: start, endDate: end };
};
