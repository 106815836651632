import React from "react";
import "./CustomToolbar.scss";
import leftArrow from "../../../Assets/arrowLeft.svg";

const CustomToolbar = ({ label, onNavigate, onView, view }) => (
  <div className="toolbar">
    <button className="todayButton" onClick={() => onNavigate("TODAY")}>
      Today
    </button>
    <div className="navigator">
      <button onClick={() => onNavigate("PREV")}>
        <img src={leftArrow} alt="" />
      </button>
      <span>{label}</span>

      <button onClick={() => onNavigate("NEXT")} style={{ rotate: "180deg" }}>
        <img src={leftArrow} alt="" />
      </button>
    </div>
    <div className="buttonControls">
      <button
        onClick={() => onView("day")}
        className={view === "day" ? "active" : ""}
      >
        Day
      </button>
      <button
        onClick={() => onView("week")}
        className={view === "week" ? "active" : ""}
      >
        Week
      </button>
      <button
        onClick={() => onView("month")}
        className={view === "month" ? "active" : ""}
      >
        Month
      </button>
    </div>
  </div>
);

export default CustomToolbar;
