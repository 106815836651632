import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCandidateId } from "../../Apis/candidateDetails";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";
import { DetailsCard } from "../../Component/AssessmentResult/DetailsCard/DetailsCard";
import { Header } from "../../Component/Header/Header";
import "./AssessmentResult.scss";
import { RatingsCard } from "../../Component/AssessmentResult/RatingsCard/RatingsCard";
import { ComentsAndDescription } from "../../Component/AssessmentResult/ComentsAndDescription/ComentsAndDescription";
import { hrQuestions as competenciesData } from "../../mockdata/hrQuestions";
import { toast } from "react-toastify";
import { ProjectEligibility } from "../../Component/AssessmentResult/ProjectEligibility/ProjectEligibility";
import { Modal, Paper, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  assignProjectToCandidate,
  submitCandidateResult,
  submitHrResult,
} from "../../Apis/submitHrResult";
import { submitRound } from "../../Apis/roundSubmission";
import congrats from "../../Assets/results/congrats.svg";

export const AssessmentResult = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [competencies, setCompetencies] = useState(competenciesData);

  const [meetingDetails] = useState({
    start: queryParams.get("start"),
    end: queryParams.get("end"),
    timezone: queryParams.get("timezone"),
    title: queryParams.get("title"),
    join_url: queryParams.get("join_url"),
    meet_id: queryParams.get("meet_id"),
  });

  const [candidateProfile, setCandidateProfile] = useState({});
  const [disableJoin, setDisableJoin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRatings, setTotalRatings] = useState(0);
  const [comments, setComments] = useState("");
  const [viewProjectEligibilty, setViewProjectEligilibily] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [project, setProject] = useState({});
  const [selectedProjectUrl, setSelectedProjectUrl] = useState("");
  const [date, setDate] = useState("");
  const [viewFinal, setViewFinal] = useState(false);
  const [viewLastSuccessCard, setViewLastSuccessCard] = useState(false);

  const handleCandidateId = async () => {
    setLoading(true);
    try {
      const res = await getCandidateId(meetingDetails.meet_id);
      setCandidateProfile({ ...res });

      if (res?.assessmentScoreRating?.round3Rating !== -1.0) {
        setDisableJoin(true);
      }
    } catch (err) {
      console.log("error  in handleCandidateId ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleResultSubmitClick = () => {
    try {
      let isCompetencies = checkAllRatings(competencies);
      if (!isCompetencies) {
        throw new Error("Please fill all competencies ratings");
      }
      if (!comments) {
        throw new Error("Please Submit feedback comment");
      }
      setViewProjectEligilibily(true);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleCloseProjectEligibility = () => {
    setViewProjectEligilibily(false);
    setIsEligible(false);
    setDate("");
    setProject("");
    setSelectedProjectUrl("");
  };

  const handleProjectSubmit = () => {
    if (!date && isEligible) {
      toast.error("Select Availability Date");
      return;
    } else if (!project.value && isEligible) {
      toast.error("Select Assigned Project");
      return;
    }
    setViewProjectEligilibily(false);
    setViewFinal(true);
  };

  const handleFinalClose = () => {
    setViewFinal(false);
    setIsEligible(false);
    setDate("");
    setProject("");
    setSelectedProjectUrl("");
  };

  const handleR4Eligibility = async () => {
    try {
      setLoading(true);
      const c_id = candidateProfile?.developerResponse["id"];
      const data = {};
      data["isEligible"] = isEligible;
      data["c_id"] = c_id;
      if (isEligible) {
        data["typeOfProject"] = "24hrs";
        data["availableDate"] = date;
      }

      const res = await submitHrResult(data);

      if (res && isEligible) {
        await handleProjectAssign();
      } else if (res) {
        handleResultSubmit();
      }
    } catch (err) {
      console.log("handleR4Eligibility error ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectAssign = async () => {
    try {
      setLoading(true);
      const data = {
        name: candidateProfile?.developerResponse["id"],
        projectName: project?.value,
        projectUrl: project?.projectUrl,
      };
      // console.log(data);
      const assignProject = await assignProjectToCandidate(data);
      handleResultSubmit();
    } catch (err) {
      console.log("handleProjectAssign error ==>", err);
    } finally {
      setLoading(false);
    }
  };
  const handleResultSubmit = async () => {
    try {
      setLoading(true);
      const id = candidateProfile?.developerResponse["id"];
      const res = await submitCandidateResult(competencies, comments, id);

      // if (project && project?.value) {
      const res2 = await submitRound(id);
      setViewFinal(false);
      setViewLastSuccessCard(true);
      // }
    } catch (err) {
      console.log("handleSubmit ===>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleFinalSubmit = async () => {
    await handleR4Eligibility();
    // await handleProjectAssign();
    // await handleResultSubmit();
    // setViewLastSuccessCard(true);
  };

  // useEffect hook to fetch data and set date/time when component mounts
  useEffect(() => {
    handleCandidateId();
  }, [meetingDetails]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} zIndex={1500} />}
      <Header />
      <div className="assessment-container">
        <DetailsCard
          meetingDetails={meetingDetails}
          candidateProfile={candidateProfile}
          disableJoin={disableJoin}
        />
        <div className="results-container">
          <RatingsCard
            setTotalRatings={setTotalRatings}
            totalRatings={totalRatings}
            competencies={competencies}
            setCompetencies={setCompetencies}
          />
          <ComentsAndDescription
            comments={comments}
            setComments={setComments}
            handleResultSubmitClick={handleResultSubmitClick}
            disableJoin={disableJoin}
          />
        </div>
      </div>
      <ProjectEligibility
        open={viewProjectEligibilty}
        handleClose={handleCloseProjectEligibility}
        isEligible={isEligible}
        setIsEligible={setIsEligible}
        setLoading={setLoading}
        candidateProfile={candidateProfile}
        setProject={setProject}
        project={project}
        selectedProjectUrl={selectedProjectUrl}
        setSelectedProjectUrl={setSelectedProjectUrl}
        setDate={setDate}
        date={date}
        handleProjectSubmit={handleProjectSubmit}
      />
      <FinalSubmit
        open={viewFinal}
        handleClose={handleFinalClose}
        handleFinalSubmit={handleFinalSubmit}
      />

      <LastSuccessCard open={viewLastSuccessCard} />
    </>
  );
};

const FinalSubmit = ({ open, handleClose, handleFinalSubmit }) => {
  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Paper className="modal">
        <IconButton className="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className="title">
          Are you sure you want to submit the results?
        </Typography>
        <Typography className="title2">
          Once submitted you will not be able to modify any ratings or comments.
        </Typography>
        <div>
          <Button className="cancel" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button className="nextButton" onClick={() => handleFinalSubmit()}>
            Submit
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

const LastSuccessCard = ({ open }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} className="modal-last">
      <Paper className="modal-last">
        <div>
          <img src={congrats} alt="" />
        </div>
        <Typography variant="h6" className="title">
          Congratulations!
        </Typography>
        <Typography className="title2">
          The results has been submitted successfully!
        </Typography>
        <div>
          <Button className="cancel" onClick={() => navigate("/mysubmissions")}>
            Go to Submissions
          </Button>
          <Button className="nextButton" onClick={() => navigate("/dashboard")}>
            Back to Dashboard
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

const checkAllRatings = (competencies) => {
  for (const element of competencies) {
    if (element.score === 0) {
      return false;
    }
  }
  return true;
};
