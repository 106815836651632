import styles from "./SubHeaderRoutes.module.scss";
import { Link, useLocation } from "react-router-dom";
import screen1 from "../../../Assets/dev-recording/screen1.svg";
import screen2 from "../../../Assets/dev-recording/screen2.svg";
import recording1 from "../../../Assets/dev-recording/video1.svg";
import recordings2 from "../../../Assets/dev-recording/video2.svg";

export const SubHeaderRoutes = ({ selectedIndex, setSelectedIndex }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <div className={styles["sub-header-routes"]}>
      <Link
        to={`/dev-recording/screen?user=${queryParams.get(
          "user"
        )}&round=${queryParams.get("round")}`}
        className={`${styles["nav-link"]} ${
          location.pathname === "/dev-recording/screen" ? styles["active"] : ""
        }`}
        onClick={() => setSelectedIndex(0)}
      >
        <img
          src={
            location.pathname === "/dev-recording/screen" ? screen1 : screen2
          }
          alt="Screen Recording"
        />
        <span>Screen Recording</span>
      </Link>
      <Link
        to={`/dev-recording/video?user=${queryParams.get(
          "user"
        )}&round=${queryParams.get("round")}`}
        className={`${styles["nav-link"]} ${
          location.pathname === "/dev-recording/video" ? styles["active"] : ""
        }`}
        onClick={() => setSelectedIndex(1)}
      >
        <img
          src={
            location.pathname === "/dev-recording/video"
              ? recording1
              : recordings2
          }
          alt="Video Recording"
        />
        <span>Video Recording</span>
      </Link>
    </div>
  );
};
