// CustomHeader.jsx
import React from "react";
import moment from "moment";
import "./CustomHeader.scss";

const CustomHeader = ({ label, date, view }) => {
  return (
    <div className="custom-header">
      {view === "month" ? (
        <div className="weekday">{moment(date).format("ddd")}</div>
      ) : (
        <>
          <div className="date">{moment(date).format("DD")}</div>
          <div className="weekday">{moment(date).format("ddd")}</div>
        </>
      )}
    </div>
  );
};

export default CustomHeader;
