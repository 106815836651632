import { RESET_PASSWORD } from "./URL";
import axiosInstance from "./axiosInstance";

export const resetPassword = async (email, password) => {
  const URL = RESET_PASSWORD

  let Config = {
    headers: {
      // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
    password: password,
  };
  //   console.log("resetPassword ===>", data);
  return await axiosInstance.post(URL, data, Config).then((res) => res);
};
