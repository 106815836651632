
import { SEND_OTP, VERIFY_OTP } from "./URL";
import axiosInstance from "./axiosInstance";

export const triggerOtp = async (email) => {

  const URL = SEND_OTP

  let Config = {
    headers: {
      // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
  };
  //   console.log("roleLogin ===>", data);
  return await axiosInstance.post(URL, data, Config).then((res) => res?.data);
};


export const verifyOtp = async (email, otp) => {

  const URL = VERIFY_OTP;

  let Config = {
    headers: {
      // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  const data = {
    officialMail: email,
    otp: otp,
  };
  //   console.log("roleLogin ===>", data);
  return await axiosInstance.post(URL, data, Config).then((res) => res?.data);
};