import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./MySubmissions.module.scss";
import { Header } from "../../Component/Header/Header";
import { getPreviousSubmissions } from "../../Apis/getPreviousSubmissions";
import { toast } from "react-toastify";
import { viewProjectAssigned } from "../../Apis/getAllProjectsHr";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";

export const MySubmissions = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGetSubmissions = async () => {
    setLoading(true);
    try {
      const hrId = sessionStorage.getItem("mail");
      const res = await getPreviousSubmissions(hrId);
      setCandidateData([...res]);
      setFilteredData([...res]);
    } catch (err) {
      console.log("handleGetSubmissions==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value.trim().toLowerCase();
    const filteredItems = candidateData.filter((item) =>
      item.candidateName.toLowerCase().includes(query)
    );
    setFilteredData([...filteredItems]);
  };

  const handleChange = (e) => {
    setName(e.target.value);
    if (e.target.value.length === 0) {
      setFilteredData([...candidateData]);
    } else {
      handleSearch(e);
    }
  };

  const handleShowProject = async (ele) => {
    if (!ele?.projectName) return;
    setLoading(true);
    try {
      const data = {
        name: ele?.candidateId,
        projectName: ele?.projectName,
      };
      const res = await viewProjectAssigned(data);
    } catch (err) {
      console.log("handleShowProject error==>", err);
      toast("Error viewing project");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSubmissions();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <Header />
      <div className={styles.container}>
        <div className={styles.title}>My Submissions</div>
        <SearchBar name={name} handleChange={handleChange} />
        <SubmissionsTable
          filteredData={filteredData}
          handleShowProject={handleShowProject}
        />
      </div>
    </>
  );
};

const SearchBar = ({ name, handleChange }) => (
  <div className={styles.searchField}>
    <div className={styles.icon}>
      <SearchIcon />
    </div>
    <div className={styles.inputName}>
      <input
        type="text"
        placeholder="Search by candidate name"
        value={name}
        onChange={(e) => handleChange(e)}
      />
    </div>
  </div>
);

const SubmissionsTable = ({ filteredData, handleShowProject }) => (
  <TableContainer component={Paper} className={styles.tableContainer}>
    <Table>
      <TableHead className={styles.tableHeader}>
        <TableRow>
          <TableCell>Candidate Name</TableCell>
          <TableCell>Date of Interview</TableCell>
          <TableCell>Next Round Allotment</TableCell>
          <TableCell>Project Assigned</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={styles.tableBody}>
        {filteredData.map((row) => (
          <TableRow key={row.name} className={styles.tableRow}>
            <TableCell className={styles.tableCell}>
              {row.candidateName}
            </TableCell>
            <TableCell className={styles.tableCell}>
              {row?.dateOfInterview || "-"}
            </TableCell>
            <TableCell className={styles.tableCell}>
              {row.nextRound?.[0]?.nextRound || "-"}
            </TableCell>
            <TableCell
              className={[styles.tableCell, styles.projectURL]}
              onClick={() => handleShowProject(row)}
            >
              {row?.projectName || "-"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
