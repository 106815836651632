// src/components/NotFoundPage.js
import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Component/Header/Header";

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Container
        component="main"
        maxWidth="md"
        sx={{ textAlign: "center", mt: 8 }}
      >
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </Typography>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/dashboard")}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </>
  );
};
