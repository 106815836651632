import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./MeetingDescription.scss";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { declineMeeting } from "../../../Apis/calendarEvents";
import { useNavigate } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import FlagIcon from "@mui/icons-material/Flag";
export const MeetingDescription = ({
  meetingDetails,
  disableJoin,
  candidateProfile,
  setLoading,
  handleCandidateId,
}) => {
  const [open, setOpen] = useState(false);
  const [viewFinal, setViewFinal] = useState(false);
  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);
  const [comments, setComments] = useState([]);

  const formattedDate = new Date(meetingDetails.start).toLocaleDateString(
    "en-GB",
    {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }
  );
  const formattedTime = new Date(meetingDetails.start).toLocaleTimeString(
    "en-GB",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );
  const duration =
    new Date(meetingDetails.end) - new Date(meetingDetails.start);
  const durationMinutes = Math.floor(duration / 60000);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirectToHome = () => {
    navigate("/dashboard");
    setViewFinal(false);
  };

  // join button click
  const handleJoinMeeting = () => {
    // Construct the query parameters
    const queryParams = new URLSearchParams({
      start: meetingDetails.start,
      end: meetingDetails.end,
      timezone: meetingDetails.timezone,
      title: meetingDetails.title,
      join_url: meetingDetails.join_url,
      meet_id: meetingDetails.meet_id,
    }).toString();

    // Navigate to /meetingDetails with query parameters
    navigate(`/assessment-result?${queryParams}`);
  };

  const handleAcceptMeeting = async () => {
    // Handle decline logic here
    setLoading(true);
    try {
      const data = {};
      const res = await declineMeeting(
        meetingDetails?.meet_id,
        data,
        "Accepted"
      );
      if (res) {
        handleCandidateId();
      }
    } catch (err) {
      console.log("error in handleAcceptMeeting==>", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newFlag =
      candidateProfile?.flaggedResponse?.round1FlaggingStatus?.userFlagged ||
      candidateProfile?.flaggedResponse?.round2FlaggingStatus?.userFlagged;
    // console.log("newFlag==>", meetingDetails);
    const newComments = [
      candidateProfile?.flaggedResponse?.round1FlaggingStatus
        ?.flaggedComments || "",
      candidateProfile?.flaggedResponse?.round2FlaggingStatus
        ?.flaggedComments || "",
    ];

    setFlag(newFlag);
    setComments(newComments);
  }, [candidateProfile]);

  return (
    <>
      <div className="meeting-description-wrapper">
        <div className="title">HR Discussion</div>
        <div className="content">
          <div className="info">
            <div className="heading">
              <h5>{meetingDetails.title}</h5>
              {flag && (
                <div className="flag">
                  <FlagIcon />
                  <span>Flag User</span>
                </div>
              )}
            </div>
            <div className="info-item">
              <span className="icon">
                <CalendarMonthIcon />
              </span>
              <span className="text">{formattedDate}</span>
            </div>
            <div className="info-item">
              <span className="icon">
                <AccessTimeIcon />
              </span>
              <span className="text">
                {formattedTime} ({durationMinutes} minutes)
              </span>
            </div>
            <div className="info-item">
              <span className="icon" style={{ color: "red" }}>
                <InfoOutlinedIcon />
              </span>
              <span className="text2">
                All times are in ({meetingDetails.timezone})
              </span>
            </div>
            {flag && (
              <div className="flag-reason">
                <span className="title">Flag Reasoning : </span>
                {comments?.[0] && (
                  <div className="reason">
                    <span className="round">Round1 : </span>
                    <span>{comments?.[0]}</span>
                  </div>
                )}
                {comments?.[1] && (
                  <div className="reason">
                    <span className="round">Round2 : </span>
                    <span>{comments?.[1]}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {candidateProfile?.hrMeetingStatus?.toLowerCase() !== "declined" &&
            !flag && (
              <div className="actions">
                {candidateProfile?.hrMeetingStatus?.toLowerCase() ===
                "pending" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disableJoin}
                    style={{
                      cursor: disableJoin ? "not-allowed" : "",
                      opacity: disableJoin ? ".7" : "1",
                    }}
                    startIcon={<EventAvailableIcon />}
                    onClick={() => handleAcceptMeeting()}
                    className="accept-button"
                  >
                    Accept
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disableJoin}
                    style={{
                      cursor: disableJoin ? "not-allowed" : "",
                      opacity: disableJoin ? ".7" : "1",
                    }}
                    onClick={() => handleJoinMeeting()}
                    className="join-button"
                  >
                    Join the Meeting
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpen(true)}
                  disabled={disableJoin}
                  style={{
                    cursor: disableJoin ? "not-allowed" : "",
                    opacity: disableJoin ? ".7" : "1",
                  }}
                  startIcon={<EventBusyIcon />}
                >
                  Decline
                </Button>
              </div>
            )}
        </div>
      </div>
      <DeclineMeetingDialog
        open={open}
        onClose={handleClose}
        meetingID={meetingDetails?.meet_id}
        setViewFinal={setViewFinal}
      />
      <MeetingDeclinedDialog open={viewFinal} onClose={handleRedirectToHome} />
    </>
  );
};

const DeclineMeetingDialog = ({ open, onClose, meetingID, setViewFinal }) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleDecline = () => {
    // Handle decline logic here
    try {
      const data = {
        reason: reason,
      };
      const res = declineMeeting(meetingID, data, "Declined");
      if (res) {
        onClose();
        setViewFinal(true);
      }
    } catch (err) {
      console.log("error in handledecline==>", err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        position: "absolute",
        right: 0,
        top: 80,
        color: (theme) => theme.palette.grey[500],
      }}
      className="decline-meeting-dialog"
    >
      <DialogTitle>
        <Typography variant="h6" className="title">
          Decline Meeting
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography className="title2">
          Are you sure you want to decline this meeting?
        </Typography>
        <Typography className="title3">
          Reason for unavailability (Optional)
        </Typography>
        <textarea
          name=""
          id="reason"
          value={reason}
          onChange={handleReasonChange}
          className="text-area"
          placeholder="Write a reason..."
        ></textarea>
      </DialogContent>
      <DialogActions className="dialog-action-buttons">
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          className="button1"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDecline}
          color="primary"
          variant="contained"
          className="button2"
        >
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MeetingDeclinedDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      sx={{
        position: "absolute",
        right: 8,
        top: 80,
        color: (theme) => theme.palette.grey[500],
      }}
      className="decline-meeting-dialog"
    >
      <DialogTitle>
        <Typography variant="h6" className="title">
          Meeting Declined
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="title2">
          Your meeting invite has been declined.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center" }}
        className="dialog-action-buttons "
      >
        <Button
          onClick={onClose}
          color="primary"
          variant="contained"
          className="button2"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
