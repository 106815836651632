import React from "react";
import "./CustomTimeGutterWrapper.scss";

const CustomTimeGutterWrapper = ({ timeZone }) => {
  return (
    <div className="custom-time-gutter-wrapper">
      <div className="timezone">{timeZone}</div>
    </div>
  );
};

export default CustomTimeGutterWrapper;
