import React, { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { useLocation, useNavigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Header } from "../../Component/Header/Header";
import { BackToDashBoard } from "../../Component/Common/BackToDashBoard/BackToDashBoard";
import "./MyAvailabillity.scss";
import { RecurringAvailability } from "../../Component/AvailabilitySetter/Recurring/RecurringAvailability";
import { CustomAvailability } from "../../Component/AvailabilitySetter/Custom/CustomAvailability";
import { NotFoundPage } from "../NotFound/NotFound";

export const AvailabilitySetter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("recurring");

  useEffect(() => {
    if (location.pathname.includes("custom")) {
      setSelected("custom");
    } else if (location.pathname.includes("recurring")) {
      setSelected("recurring");
    }
  }, [location]);

  const handleSelectionChange = (value) => {
    setSelected(value);
    navigate(`/my-availability/${value}`);
  };

  const isNotFoundRoute = ![
    "/my-availability/recurring",
    "/my-availability/custom",
    "/my-availability/",
  ].includes(location.pathname);

  if (isNotFoundRoute) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Header />
      <div className="availability-container">
        <BackToDashBoard />
        <div className="title">Set Availability</div>
        <AvailabilityCheckboxes
          selected={selected}
          setSelected={handleSelectionChange}
        />
        <div className="desc">
          Please select the days from the options below to set your
          availability:
        </div>
        <Routes>
          <Route path="recurring" element={<RecurringAvailability />} />
          <Route path="custom" element={<CustomAvailability />} />
          <Route path="/" element={<RecurringAvailability />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </>
  );
};

function AvailabilityCheckboxes({ selected, setSelected }) {
  const handleChange = (event) => {
    const value = event.target.name;
    setSelected(value);
  };

  return (
    <Box className="availability-checkbox">
      <FormControlLabel
        control={
          <Checkbox
            checked={selected === "recurring"}
            onChange={handleChange}
            name="recurring"
          />
        }
        className="item"
        label="Recurring availability"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={selected === "custom"}
            onChange={handleChange}
            name="custom"
          />
        }
        label="Custom availability"
      />
    </Box>
  );
}
