import loginIllustration from "../../Assets/login/background.svg";
import { TopLogo } from "../../Component/TopLogo/TopLogo";
import { Login } from "./Login";
import "./Login.scss";

export const LoginMain = () => {
  return (
    <div className="login-main">
      <TopLogo />
      <div className="purple-item"></div>
      <div className="content-page">
        <div className="item-main">
          <div>
            <img src={loginIllustration} alt="" />
          </div>
          <div>
            <Login />
          </div>
        </div>
      </div>
    </div>
  );
};
