// src/Dashboard.js
import React, { useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import {
  EventNote,
  PendingActions,
  AssignmentTurnedIn,
  AssignmentLate,
} from "@mui/icons-material";
import "./Stats.scss";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";

export const DashboardStats = ({ currentMonthDetails }) => {
  const navigate = useNavigate();
  const [stats, setStats] = useState([
    {
      icon: <EventNote />,
      label: "Meetings for Today",
      value: currentMonthDetails?.meetingsOnDate,
    },
    {
      icon: <PendingActions />,
      label: "Pending Meetings in Current month",
      value: currentMonthDetails?.meetingsPending,
    },
    {
      icon: <EventNote />,
      label: "Meetings Completed in Current month",
      value: currentMonthDetails?.meetingsCompleted,
    },
    {
      icon: <AssignmentTurnedIn />,
      label: "Meetings completed yearly till date",
      value: "n/a",
    },
    {
      icon: <AssignmentLate />,
      label: "Pending Project Assignments",
      value: currentMonthDetails?.pending,
    },
  ]);

  return (
    <Container className="dashboard-container">
      <Typography className="row-1">
        <div className="header">Welcome 👋</div>
        <button
          className="availability"
          onClick={() => navigate("/my-availability/")}
        >
          <CalendarMonthOutlinedIcon />
          <div>Set Availability</div>
        </button>
      </Typography>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        gutterBottom
        className="header2"
      >
        Lets take a moment to check your interview planned.
      </Typography>
      <Grid container spacing={3} className="cards-container">
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card className="dashboard-card">
              <Avatar className="dashboard-avatar">{stat?.icon}</Avatar>
              <CardContent className="dashboard-card-content">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="label"
                >
                  {stat?.label}
                </Typography>
                <Typography variant="h5" className="value">
                  {stat.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
