import {
  ASSIGN_PROJECT_TO_CANDIDATE,
  HANDLE_SUBMIT_HR_RESULT,
  SUBMIT_HR_RATING,
} from "./URL";
import axiosInstance from "./axiosInstance";

export const submitHrResult = async (data) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    headers: {},
  };

  const URL = HANDLE_SUBMIT_HR_RESULT;

  const name = data?.c_id;
  const isEligibleForRound4 = data.isEligible;

  const payload = {
    name: name,
    // name: "mvananthu@gmail.com",
    isEligibleForRound4: isEligibleForRound4,
  };

  if (data?.availableDate) {
    let date = formatDate(data?.availableDate);
    payload["availableDate"] = `${date} 15:30:45`;
  }

  if (data?.typeOfProject) {
    payload["typeOfProject"] = data?.typeOfProject;
  }

  console.log("submitHrResult ==>", payload);

  return await axiosInstance.post(URL, payload, Config).then((res) => res.data);
  // return false;
};

function formatDate(dateStr) {
  // Split the input date string into an array of [year, month, day]
  const [year, month, day] = dateStr.split("-");

  // Return the formatted date string in the format DD-MM-YYYY
  return `${day}-${month}-${year}`;
}

export const assignProjectToCandidate = async (data) => {
  const URL = ASSIGN_PROJECT_TO_CANDIDATE;

  let Config = {
    // headers: {
    //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
  };

  return await axiosInstance.post(URL, data, Config).then((res) => res);
};

export const submitCandidateResult = async (competencies, comments, id) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    headers: {},
  };

  const URL = SUBMIT_HR_RATING;

  const data = formatSubmitData(competencies, comments, id);
  // console.log("submitCandidateResult ===>", data);
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};

const formatSubmitData = (competencies, comments, id) => {
  let combineRatingAndTopic = [];
  competencies.forEach((element) => {
    combineRatingAndTopic.push({
      section: element.title,
      rating: element.score,
      comments: element.comment,
    });
  });

  const data = {
    id: id,
    sections: combineRatingAndTopic,
    comments: comments,
  };
  return data;
};
