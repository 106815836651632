import "./ForgotPassword.scss";
import forgotImage from "../../Assets/forgot-password.svg";
import { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
// import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { triggerOtp, verifyOtp } from "../../Apis/otpServices";
import { toast } from "react-toastify";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";
import { resetPassword } from "../../Apis/resetPassword";
import { useNavigate } from "react-router-dom";

export const ForgotPasswprd = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [viewEmailScreen, setViewEmailScreen] = useState(true);
  const [viewOtpScreen, setViewOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [viewSetPasswords, setViewSetPasswords] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const [strength, setStrength] = useState({
    hasNumber: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasLetter: false,
    hasLowercase: false,
    isMinLength: false,
  });
  const [samePassword, setSamePassword] = useState(false);
  const [passwordStregthError, setPasswordStregthError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendOtp = async (email) => {
    try {
      const data = await triggerOtp(email);
      if (data) {
        toast.success("Otp sent successfully!");
        return true;
      }
    } catch (err) {
      let msg;
      if (err?.response?.data) msg = err?.response?.data;
      else msg = "Something went wrong! try again";
      toast.error(msg);
      return false;
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setUserNotFound(false);
    setLoading(true);
    try {
      const data = await triggerOtp(email);
      if (data) {
        toast.success("Otp sent successfully!");
        setViewOtpScreen(true);
        setViewEmailScreen(false);
        setTimeLeft(30);
        setIsTimerActive(true);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      let msg;
      if (err?.response?.data) msg = err?.response?.data;
      else msg = "Something went wrong! try again";
      const userNotFound =
        "User does not exixts in the database. Please sign up first.";
      if (msg === userNotFound) setUserNotFound(true);
      else toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error("Otp should be of 6 digits");
      return;
    }
    try {
      const data = await verifyOtp(email, otp);
      // console.log("handleOtpSubmit otp verify==>", data);
      if (data) {
        setViewOtpScreen(false);
        setViewEmailScreen(false);
        setViewSetPasswords(true);
      } else {
        setOtpError(true);
        throw new Error("otp verification error");
      }
    } catch (err) {
      setOtpError(true);
      console.log("error in otp verification==>", err);
    }
  };

  const handleEditClick = () => {
    setOtp("");
    setViewOtpScreen(false);
    setViewEmailScreen(true);
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    // Check if the value is NaN or exceeds 999999
    if (isNaN(value) || value > 999999 || value < 0) {
      // If invalid, set the quantity state to an empty string
      // setOtp("");
      return;
    } else {
      // If valid, update the quantity state
      if (otpError) {
        setOtpError(false);
      }
      setOtp(value);
    }
  };

  const handleResentOtp = async () => {
    if (timeLeft > 0) return;
    console.log("starting timer");
    try {
      const res = await sendOtp(email);
      if (res) {
        setTimeLeft(30);
        setIsTimerActive(true);
      }
    } catch (err) {
      console.log("handle resent", err);
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordStregthError(false);
    setSamePassword(false);

    // Check for at least one number
    const hasNumber = /\d/.test(newPassword);

    // Check for at least one uppercase letter
    const hasUppercase = /[A-Z]/.test(newPassword);

    // Check for at least one special character
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword);

    // Check for at least one letter (either uppercase or lowercase)
    const hasLetter = /[A-Za-z]/.test(newPassword);

    // Check for at least one lowercase letter
    const hasLowercase = /[a-z]/.test(newPassword);

    // Check for minimum length of 8 characters
    const isMinLength = newPassword.length >= 8;

    // Update the state with the new password and strength information
    setPassword(newPassword);
    setStrength({
      hasNumber,
      hasUppercase,
      hasSpecialChar,
      hasLetter,
      hasLowercase,
      isMinLength,
    });
  };

  const getIndicatorStyle = (criteriaMet) => ({
    color: criteriaMet ? "#34A853" : passwordStregthError ? "red" : "#000",
  });

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    for (let item in strength) {
      if (!strength[item]) {
        setPasswordStregthError(true);
        return;
      }
    }

    if (password !== confirmPassword) {
      setSamePassword(true);
      return;
    }

    try {
      const data = await resetPassword(email, password);

      if (data?.data && data?.headers?.authorization) {
        toast.success("password Updated Successfully");
        navigate("/login");
      }
    } catch (err) {
      console.log("handlePasswordSubmit error==>", err);
      let msg;
      if (err?.response?.data?.exception) msg = err?.response?.data?.exception;
      else msg = "Something went wrong! try again";
      toast.error(msg);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (!timeLeft) {
      setIsTimerActive(false);
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // Add timeLeft as a dependency to reset the interval
    // whenever the timeLeft changes
  }, [timeLeft, isTimerActive]);

  return (
    <>
      <LoadingOverlay loading={loading} />
      <div className="forgot-password-main">
        <div className={`left-side-main childDiv`}>
          {/* <TopLogo /> */}
          <div></div>
          <div>
            <img src={forgotImage} alt="item" />
          </div>
        </div>
        {viewEmailScreen && (
          <div className={`right-side-main childDiv`}>
            <div className="right-item">
              <div className="title">Forgot Password</div>
              <div className="subtitle">
                Provide us the Email address of your Zeero account and we will
                send you an OTP to your registered email with instructions to
                reset your password.
              </div>
              <div className="form-wrapper">
                <form onSubmit={handleSendOtp}>
                  <div>
                    <label className="label">Email</label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="content-input"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => {
                        setUserNotFound(false);
                        setEmail(e.target.value);
                      }}
                    />
                    {userNotFound && (
                      <div className="otpError">
                        <ErrorIcon /> User not Found
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="submit"
                      className="last-button"
                      value="Send OTP"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {viewOtpScreen && (
          <div className={`right-side-main childDiv`}>
            <div className="right-item">
              <div className="title">OTP Verification</div>
              <div className={"subtitle subtitle2"}>
                We have sent you an OTP on <span>{email}</span>{" "}
                <span onClick={() => handleEditClick()}>Edit</span>
              </div>
              <div className="form-wrapper">
                <form onSubmit={handleVerifyOtp}>
                  <div>
                    <label className="label">OTP</label>
                    <input
                      style={{
                        border: otpError ? "1px solid #E5261C" : "",
                        // color: "#4318FF",
                      }}
                      type="number"
                      name="otp"
                      required
                      className="content-input"
                      placeholder="000000"
                      value={otp}
                      min="1"
                      max="999999"
                      onChange={(e) => handleOtpChange(e)}
                    />
                    {otpError && (
                      <div className="otpError">
                        <ErrorIcon />
                        Wrong OTP entered
                      </div>
                    )}
                  </div>
                  <div className="resendOtp">
                    Didn’t receive the code?{" "}
                    <span
                      style={{ opacity: timeLeft > 0 ? ".6" : "1" }}
                      onClick={() => handleResentOtp()}
                    >
                      {" "}
                      Resend OTP
                    </span>{" "}
                    {timeLeft > 0 && <span>{timeLeft}</span>}
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="submit"
                      className="last-button"
                      value="Verify OTP"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        {viewSetPasswords && (
          <div className={`right-side-main childDiv`}>
            <div className="right-item">
              <div className="title">Reset Password</div>

              <div className="form-wrapper">
                <form onSubmit={handleConfirmPassword}>
                  <div className="password-wrapper">
                    <label className="label">New Password</label>
                    {/* <input
                    type="password"
                    required
                    className="content-input"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => handlePasswordChange(e)}
                  /> */}

                    <TextField
                      type={showPassword ? "text" : "password"}
                      placeholder="New Password"
                      fullWidth
                      className="content-input"
                      required
                      name="password"
                      autoComplete="false"
                      style={{ backgroundColor: "white", padding: "0px" }}
                      value={password}
                      onChange={(e) => handlePasswordChange(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="criteria">
                      <span>*Password Policy: </span>
                      <span style={getIndicatorStyle(strength.hasNumber)}>
                        1 number,
                      </span>
                      <span style={getIndicatorStyle(strength.hasUppercase)}>
                        1 uppercase character
                      </span>
                      <span style={getIndicatorStyle(strength.hasSpecialChar)}>
                        1 special character,
                      </span>
                      <span style={getIndicatorStyle(strength.hasLetter)}>
                        1 letter,
                      </span>
                      <span style={getIndicatorStyle(strength.hasLowercase)}>
                        1 lowercase character
                      </span>
                      <span style={getIndicatorStyle(strength.isMinLength)}>
                        Minimum of 8 characters
                      </span>
                    </div>
                  </div>
                  <div>
                    <label className="label">Confirm Password</label>
                    {/* <input
                    type="password"
                    required
                    className="content-input"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setSamePassword(false);
                      setConfirmPassword(e.target.value);
                    }}
                  /> */}

                    <TextField
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      fullWidth
                      className="content-input"
                      required
                      name="password"
                      autoComplete="false"
                      style={{
                        backgroundColor: "white",
                        padding: "0px",
                        outline: "none",
                      }}
                      value={confirmPassword}
                      onChange={(e) => {
                        setSamePassword(false);
                        setConfirmPassword(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {samePassword && (
                      <div className="otpError">
                        <ErrorIcon /> Password and Confirm Password should be
                        same
                      </div>
                    )}
                    {passwordStregthError && (
                      <div className="otpError">
                        <ErrorIcon /> Password do not meet criteria
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      type="submit"
                      className="last-button"
                      value="Confirm"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
