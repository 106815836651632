import React from "react";
import "./Tooltip.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const Tooltip = ({
  hoveredEvent,
  tooltipPosition,
  handleTooltipMouseEnter,
  handleTooltipMouseLeave,
  tooltipRef,
  handleJoinMetting,
}) => {
  return (
    hoveredEvent && (
      <div
        className="tooltip"
        style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
        onMouseEnter={handleTooltipMouseEnter}
        onMouseLeave={handleTooltipMouseLeave}
        ref={tooltipRef}
      >
        <h3>{hoveredEvent.title}</h3>
        <p>
          <span role="img" aria-label="calendar">
            <CalendarMonthIcon />
          </span>{" "}
          {hoveredEvent.start.toLocaleDateString()}
        </p>
        <p>
          <span role="img" aria-label="clock">
            <AccessTimeIcon />
          </span>{" "}
          {hoveredEvent.start.toLocaleTimeString()} -{" "}
          {hoveredEvent.end.toLocaleTimeString()}
        </p>
        <button
          onClick={() => handleJoinMetting(hoveredEvent, "/assessment-result")}
          disabled={hoveredEvent?.hrMeetingStatus?.toLowerCase() === "declined"}
          style={{
            opacity:
              hoveredEvent?.hrMeetingStatus?.toLowerCase() === "declined"
                ? 0.7
                : 1,
          }}
        >
          Join the Meeting
        </button>
      </div>
    )
  );
};

export default Tooltip;
