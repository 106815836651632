
import { GET_SUBMISSIONS_LIST } from "./URL";
import axiosInstance from "./axiosInstance";

export const getPreviousSubmissions = async (hrId) => {
  let Config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };
  const URL = GET_SUBMISSIONS_LIST;

  const data = {
    hrId: hrId,
    date: currentData(),
  };

  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};

function currentData() {
  var today = new Date();
  var month = String(today.getMonth() + 1).padStart(2, "0");

  console.log(
    "checkDateEmpty from submission page ===>",
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
    "c",
    today.getDate()
  );

  const todayDate =
    today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

  var date = todayDate + "-" + month + "-" + today.getFullYear();
  // var date = "11-07-2023";

  console.log("date formatted==>", date);

  return date;
}
