import React, { useEffect, useState } from "react";
import styles from "./CandidateDetails.module.scss";
import pdfIcon from "../../../Assets/pdfIcon.svg";
import { getCandidateResume } from "../../../Apis/candidateDetails";
import LoadingOverlay from "../../Common/LoadingOverlay";

const constantValues = {
  title1: "Candidate Details",
  subTitle1: "Personal Details",
  subTitle2: "Technical Skills",
  Name: "Name",
  Email: "Email",
  mobileNumber: "Mobile Number",
  workExperience: "Work Experience",
  languageAndFrameworkSelected: "Language/Framework selected",
};

export const CandidateDetails = ({ candidateProfile }) => {
  const [selectedLAnguages, setSelectedLanguages] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDownLoadResume = async () => {
    setLoading(true);
    try {
      const data = {
        id: candidateProfile?.developerResponse?.id,
      };
      const res = await getCandidateResume(data);
      const downloadLink = document.createElement("a");
      downloadLink.href = res;
      // Set the file name
      downloadLink.setAttribute("download", "resume.pdf");
      // Trigger the download by programmatically clicking the link
      downloadLink.click();
      console.log("handleDownload==>", res);
    } catch (err) {
      console.log("handleDownload error ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedLanguages = () => {
    // Extracting the checked languages and their frameworks
    const checkedItems =
      candidateProfile?.developerSkills?.skillsdev
        ?.flatMap((skilldev) => skilldev.languages)
        ?.filter((language) => language.checked)
        ?.map((language) => {
          const frameworks = language?.frameworks
            ?.filter((f) => f?.checked)
            ?.map((f) => f?.framework);
          return frameworks?.length > 0
            ? `${language?.language} | ${frameworks?.join(" | ")}`
            : language?.language;
        }) || [];
    setSelectedLanguages([...checkedItems]);
    // console.log("checked item==>", checkedItems);
  };

  useEffect(() => {
    handleSelectedLanguages();
  }, [candidateProfile]);

  return (
    <>
      {loading ? (
        <LoadingOverlay loading={loading} />
      ) : (
        <div className={styles.candidateDetailsContainer}>
          <div className={styles.title}>{constantValues.title1}</div>
          <div className={styles.profileCard}>
            <div className={styles.personalDetails}>
              <h2>{constantValues?.subTitle1}</h2>
              <p>
                <span> {constantValues?.Name} : </span>{" "}
                {candidateProfile?.developerResponse?.firstName}{" "}
                {candidateProfile?.developerResponse?.lastName}
              </p>
              <p>
                <span>{constantValues?.Email} :</span>
                {candidateProfile?.developerResponse?.id}
              </p>
              <p>
                <span>{constantValues?.mobileNumber} :</span>
                {candidateProfile?.developerResponse?.mobile}
              </p>
              <p>
                <span>{constantValues?.workExperience} :</span>
                {candidateProfile?.developerResponse?.experienceInYears}
              </p>
            </div>
            <div className={styles.technicalSkills}>
              <h2>{constantValues?.subTitle2}</h2>
              <p>
                <span>{constantValues?.languageAndFrameworkSelected} :</span>
                {selectedLAnguages?.length > 0 ? (
                  selectedLAnguages.map((item, index) => (
                    <span key={index}>
                      {item}
                      {index < selectedLAnguages?.length - 1 ? " | " : ""}
                    </span>
                  ))
                ) : (
                  <span></span>
                )}
              </p>
              <div
                className={styles.resume}
                onClick={() => handleDownLoadResume()}
              >
                <span className={styles.resumeIcon}>
                  <img src={pdfIcon} alt="pdf" />
                </span>
                <p className={styles.resumeLink}>
                  {candidateProfile?.developerResponse?.firstName.toLowerCase() +
                    "_" +
                    candidateProfile?.developerResponse?.lastName.toLowerCase()}
                  _resume.pdf
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
