import React, { useEffect } from "react";
import "./EventComponent.scss";

const EventComponent = ({ event, onHover, onLeave, onClick }) => {
  useEffect(() => {
    const current = document.getElementById(event.title);
    if (current) {
      const twoLevelUpParent = current.parentElement?.parentElement;
      if (twoLevelUpParent) {
        const handleClick = (e) => {
          handleEventClick(event, e);
        };

        twoLevelUpParent.addEventListener("click", handleClick);

        // Clean up the event listener on component unmount
        return () => {
          twoLevelUpParent.removeEventListener("click", handleClick);
        };
      }
    }
  }, []);

  const handleEventClick = (event, e) => {
    e.stopPropagation();
    onClick(event, e);
  };

  return (
    <div
      className={`event ${
        event?.hrMeetingStatus?.toLowerCase() === "declined" ? "declined" : ""
      }`}
      id={event.title}
      onMouseEnter={(e) => onHover(event, e)}
      onMouseLeave={onLeave}
      onClick={(e) => handleEventClick(event, e)}
    >
      {event.title}
    </div>
  );
};

export default EventComponent;
