import React, { useEffect, useState } from "react";
import "./RatingsCard.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import emptyStar from "../../../Assets/results/empty-star.svg";
import filledStar from "../../../Assets/results/filled-star.svg";

export const RatingsCard = ({
  totalRatings,
  setTotalRatings,
  competencies,
  setCompetencies,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleTileClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleRatingClick = (index, rating) => {
    const updatedCompetencies = competencies.map((competency, i) =>
      i === index ? { ...competency, score: rating } : competency
    );
    setCompetencies(updatedCompetencies);
  };

  const handleCommentChange = (index, event) => {
    const updatedCompetencies = competencies.map((competency, i) =>
      i === index ? { ...competency, comment: event.target.value } : competency
    );
    setCompetencies(updatedCompetencies);
  };

  const getTotalScore = () => {
    const total = competencies.reduce(
      (acc, competency) => acc + competency.score,
      0
    );
    // return (total / competencies.length).toFixed(2);
    const finalRating = (total / competencies.length).toFixed(2);
    setTotalRatings(finalRating);
  };

  useEffect(() => {
    getTotalScore();
  }, [competencies]);

  return (
    <div className="competency-card">
      {competencies.map((competency, index) => (
        <div
          key={index}
          className="competency-tile"
          onClick={() => handleTileClick(index)}
        >
          <div className="competency-header">
            <div className="competency-title">
              <span
                className={`arrow ${expandedIndex === index ? "open" : ""}`}
              >
                <KeyboardArrowRightIcon />
              </span>
              <span>{competency?.title}</span>
            </div>
            <div className="stars">
              {[...Array(5)].map((star, i) => (
                <span
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRatingClick(index, i + 1);
                  }}
                >
                  {i < competency.score ? (
                    <img src={filledStar} alt="" />
                  ) : (
                    <img src={emptyStar} alt="" />
                  )}
                </span>
              ))}
            </div>
          </div>
          {expandedIndex === index && (
            <div className="competency-description">
              <div>
                {competency?.description?.map((item, index) => (
                  <div key={index} className="each-desc">
                    <span className="index">{index + 1}.</span>
                    <span className="desc">{item?.desc}</span>
                  </div>
                ))}
              </div>
              <div className="competency-comment">
                <textarea
                  name="comment"
                  className="comment"
                  id="competency-comment-tag"
                  onClick={(e) => e.stopPropagation()}
                  placeholder="feedback..."
                  value={competencies[index].comment}
                  onChange={(event) => handleCommentChange(index, event)}
                ></textarea>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="total-score">
        <div>
          <div className="total">Total Score</div>
          <div className="avg">(Average of all competencies score)</div>
        </div>
        <div className="score">
          {/* <span>{getTotalScore()}</span> */}
          <span>{totalRatings}</span>
          <span className="score-star">
            <img src={filledStar} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RatingsCard;
