import axios from "axios";

const axiosInstance = axios.create({
    // Add any global configurations here
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            sessionStorage.removeItem("token");
            window.location.href = "/login";
            localStorage.removeItem("meetingDetails")
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
