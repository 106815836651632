import React from "react";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import "./BackToDashBoard.scss"; // Import SCSS file

export const BackToDashBoard = ({
  url = "/dashboard",
  label = "Back to dashboard",
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    // Handle back button click event
    navigate(url);
  };

  return (
    <div className="back-to-dashboard" onClick={handleBackClick}>
      <div className="back-to-dashboard-content">
        <IconButton onClick={handleBackClick} className="arrow">
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="body1" className="back-to-dashboard-text">
          {label}
        </Typography>
      </div>
      {/* Dashboard content */}
    </div>
  );
};
