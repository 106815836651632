import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { LoginMain } from "../Pages/Login/LoginMain";
import { Dashboard } from "../Pages/Dashboard/Dashboard";
import { MeetingDetails } from "../Pages/MeetingDetails/MeetingDetails";
import { ViewRecordings } from "../Pages/ViewRecordings/ViewRecordings";
import { AssessmentResult } from "../Pages/AssessmentResult/AssessmentResult";
import { MySubmissions } from "../Pages/MySubmissions/MySubmissions";
import { NotFoundPage } from "../Pages/NotFound/NotFound";
import { ForgotPasswprd } from "../Pages/ForgotPassword/ForgotPassword";
import { AvailabilitySetter } from "../Pages/MyAvailabillity/MyAvailabillity";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginMain />} />
        <Route path="/login" element={<LoginMain />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/meetingDetails" element={<MeetingDetails />} />
        <Route path="/dev-recording/*" element={<ViewRecordings />} />
        <Route path="/assessment-result" element={<AssessmentResult />} />
        <Route path="/mysubmissions" element={<MySubmissions />} />
        <Route path="/my-availability/*" element={<AvailabilitySetter />} />
        <Route path="/forgot-password" element={<ForgotPasswprd />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
};
