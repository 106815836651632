import "./TopLogo.scss";
import zeero from "../../Assets/zeero.svg";
import { useNavigate } from "react-router-dom";

export const TopLogo = () => {
  const navigate = useNavigate();
  return (
    <div className="zeero-logo-top" onClick={() => navigate("/")}>
      <img src={zeero} alt="zeero" />
    </div>
  );
};
