import { useEffect, useState } from "react";
import { BackToDashBoard } from "../../Component/Common/BackToDashBoard/BackToDashBoard";
import { Header } from "../../Component/Header/Header";
import LoadingOverlay from "../../Component/Common/LoadingOverlay";
import "./ViewRecordings.scss";
import { SubHeaderRoutes } from "../../Component/ViewRecordings/SubHeaderRoutes/SubHeaderRoutes";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { VideoRecording } from "../../Component/ViewRecordings/VideoRecording/VideoRecording";
import { ScreenRecording } from "../../Component/ViewRecordings/ScreenRecording/ScreenRecording";
import { styled } from "@mui/system";

import {
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { Close } from "@mui/icons-material";
import { flagUser } from "../../Apis/flagUser";
import { getCandidateId } from "../../Apis/candidateDetails";

export const ViewRecordings = () => {
  const [loading, setLoading] = useState(false);
  const [selecetdIndex, setSelectedIndex] = useState(0);
  const [candidateProfile, setCandidateProfile] = useState({});
  const [meetingDetails, setMeetingDetails] = useState(() => {
    return JSON.parse(localStorage.getItem("meetingDetails"));
  });
  const [flag, setFlag] = useState(false);
  const [comments, setComments] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const round = queryParams.get("round");

  const queryParamsToRedirect = new URLSearchParams({
    start: meetingDetails?.start,
    end: meetingDetails?.end,
    timezone: meetingDetails?.timezone,
    title: meetingDetails?.title,
    join_url: meetingDetails?.join_url,
    meet_id: meetingDetails?.meet_id,
  }).toString();

  const url = `/meetingDetails?${queryParamsToRedirect}`;

  const handleCandidateId = async () => {
    setLoading(true);
    try {
      const res = await getCandidateId(meetingDetails.meet_id);
      setCandidateProfile({ ...res });
    } catch (err) {
      console.log("error in handleCandidateId ==>", err);
    } finally {
      setLoading(false);
    }
  };

  const handleFlagAndComment = () => {
    let newFlag = false;
    let newComments = [];

    if (
      round === "round1" &&
      candidateProfile?.flaggedResponse?.round1FlaggingStatus?.userFlagged
    ) {
      newFlag = true;
      newComments = [
        candidateProfile?.flaggedResponse?.round1FlaggingStatus
          ?.flaggedComments,
      ];
    } else if (
      round === "round2" &&
      candidateProfile?.flaggedResponse?.round2FlaggingStatus?.userFlagged
    ) {
      newFlag = true;
      newComments = [
        candidateProfile?.flaggedResponse?.round2FlaggingStatus
          ?.flaggedComments,
      ];
    }

    setFlag(newFlag);
    setComments(newComments);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await flagUser(data);
      handleCandidateId();
    } catch (err) {
      console.log("handleGetSubmissions==>", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (meetingDetails?.meet_id) {
      handleCandidateId();
    }
  }, [meetingDetails?.meet_id]);

  useEffect(() => {
    handleFlagAndComment();
  }, [candidateProfile]);

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <Header />
      <div className="view-recordings-Container">
        <BackToDashBoard label="Back" url={url} />
        <FlagUser
          handleSubmit={handleSubmit}
          flag={flag}
          comments={comments}
          round={round}
        />
        <SubHeaderRoutes
          selecetdIndex={selecetdIndex}
          setSelectedIndex={setSelectedIndex}
        />
        {flag && <FlagDesc comment={comments} />}
        <Routes>
          <Route
            path="/video"
            element={<VideoRecording setLoading={setLoading} />}
          />
          <Route
            path="/screen"
            element={<ScreenRecording setLoading={setLoading} />}
          />
          <Route path="/" element={<Navigate to="video" />} />
        </Routes>
      </div>
    </>
  );
};

const FlagDesc = ({ comment }) => {
  return (
    <div className="flag-desc">
      <span className="title">Flag Reasoning : </span>
      <span className="reason">{comment?.[0]}</span>
    </div>
  );
};

const FlagUser = ({ handleSubmit, flag, comments, round }) => {
  const [open, setOpen] = useState(false);
  const [unflagOpen, setUnflagOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnflagOpen = () => {
    setUnflagOpen(true);
  };
  const handleUnflagClose = () => {
    setUnflagOpen(false);
  };

  return (
    <div className="flag-user">
      {flag && (
        <IconButton className="flag-icon unflag" onClick={handleUnflagOpen}>
          <FlagIcon /> <span>Unflag User</span>
        </IconButton>
      )}
      <Tooltip title="In case candidate is found to use unethical means then click this button">
        <IconButton className="flag-icon" onClick={handleClickOpen}>
          <FlagIcon /> <span>{flag ? "Edit Flag" : "Flag User"}</span>
        </IconButton>
      </Tooltip>
      <FlagDialog
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        comments={comments}
        flag={flag}
      />
      <UnflagDialog
        open={unflagOpen}
        onClose={handleUnflagClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

const FlagDialog = ({ open, onClose, onSubmit, comments, flag }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    // Extract email from URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const candidateEmail = urlParams.get("user"); // Assuming 'email' is the query parameter name
    const round = urlParams.get("round");

    if (!candidateEmail) {
      console.error("Email not found in URL parameters.");
      handleClose();
      return;
    }
    // Construct the data object
    const data = {
      candidateEmail: candidateEmail,
      userFlagged: selectedValue === "yes", // Assuming selectedValue is defined in your context
      flaggedComments: selectedValue === "yes" ? message : "", // Assuming message is defined in your context
      round: round, // Assuming the round value is static
    };

    // Submit the data
    onSubmit(data);

    // Close the form/modal
    handleClose();
  };

  const handleClose = () => {
    setSelectedValue("");
    setMessage("");
    onClose();
  };

  const CustomRadio = styled(Radio)({
    color: "#858585",
    "&.Mui-checked": {
      color: "#062B6E",
    },
  });

  // Disable submit button if "Yes" is selected but no message is provided
  const isSubmitDisabled = selectedValue === "yes" && !message;

  useEffect(() => {
    if (open) {
      setSelectedValue(() => (flag ? "yes" : ""));
      setMessage(comments?.[0]);
    }
  }, [open, flag]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="flag-user-container"
      maxWidth="xs"
      fullWidth
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiDialog-paper": {
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      <DialogActions className="first">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogTitle className="title">
        Are you sure you want to flag this user?
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
          className="radio-button"
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
        {selectedValue === "yes" && (
          <>
            <TextField
              className="message"
              aria-label="Message"
              multiline
              rows={4}
              placeholder="Write comment..."
              fullWidth
              variant="outlined"
              value={message}
              onChange={handleMessageChange}
            />
            <div className="desc">
              * Provide detailed explanation and time stamp for integrity
              issues.
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className="button-container">
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedValue || isSubmitDisabled} // Disable if selectedValue is empty or submit is disabled
          className="submit-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UnflagDialog = ({ open, onClose, onSubmit }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (selectedValue === "yes") {
      // Extract email from URL query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const candidateEmail = urlParams.get("user"); // Assuming 'user' is the query parameter name
      const round = urlParams.get("round");

      if (!candidateEmail) {
        console.error("Email not found in URL parameters.");
        handleClose();
        return;
      }

      // Construct the data object
      const data = {
        candidateEmail: candidateEmail,
        userFlagged: false, // Assuming selectedValue is defined in your context
        flaggedComments: "", // Assuming message is defined in your context
        round: round, // Assuming the round value is static
      };

      // Submit the data
      onSubmit(data);
    }

    // Close the form/modal
    handleClose();
  };

  const handleClose = () => {
    setSelectedValue("");
    onClose();
  };

  const CustomRadio = styled(Radio)({
    color: "#858585",
    "&.Mui-checked": {
      color: "#062B6E",
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className="flag-user-container"
      maxWidth="xs"
      fullWidth
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiDialog-paper": {
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      <DialogActions className="first">
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogTitle className="title">
        Are you sure you want to unflag this user?
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={selectedValue}
          onChange={handleChange}
          className="radio-button"
        >
          <FormControlLabel value="yes" control={<CustomRadio />} label="Yes" />
          <FormControlLabel value="no" control={<CustomRadio />} label="No" />
        </RadioGroup>
      </DialogContent>
      <DialogActions className="button-container">
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!selectedValue} // Disable if selectedValue is empty
          className="submit-button"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnflagDialog;
